import { useTranslation } from 'react-i18next';
import GetStarted from './get-started';


const Section4: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();



    return (
        <div className='basic-11'>
            <div className="container">
                <div className="card">
                    <div className='row d-flex justify-content-center align-items-center align-self-center'>
                        <div className='col-lg-12 col-xl-12 '>
                            <h1> {t("Take your real estate")} </h1>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                    <GetStarted Style={"btn-solid-lg"} Value={`${t("Get Started")}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Section4;