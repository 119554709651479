import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import GetStarted from "../home/get-started";
import BlogContact from "./blog-contact";
import Footer from "../home/footer";
import Newsletter from "./newsletter";


const Blog2: React.FunctionComponent<{}> = (props) => {


    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    const handleShareLinkedIn = () => {
        const url = window.location.href;
        const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
        window.open(linkedInShareUrl, '_blank'); 
    };

    return (<>
        <Helmet>
            <title>{t("blog title 2")}</title>
            <meta name="description" content={t("When it comes to finding the perfect")} />
        </Helmet>

        <header id="header" className="header page-blogs dir-rtl">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="text-container">
                            <h1 className="h1-large text-center">{t("blog title 2")}</h1>
                            <p className="p-large text-center">{t("When it comes to finding the perfect")}</p>
                            <div className="d-flex justify-content-center mb-4">
                                <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div className="container dir-rtl blog-1">
            <div className="row">
                <div className="col-md-8">
                    <div className="row ">
                        <div className="col-auto mb-2">
                            <p className="title-tags">{t("Written By")}</p>
                            <p className="p-tags">{t("Micheal")}</p>
                        </div>
                        <div className="col-auto mb-2">
                            <p className="title-tags">{t("Published On")}</p>
                            <p className="p-tags">{t("January 21, 2024")}</p>
                        </div>
                        <div className="col-auto mb-2">
                            <p className="title-tags">{t("Category")}</p>
                            <p className="p-tags">{t("Real Estate")}</p>
                        </div>
                    </div>

                </div>
                <div className="col-md-4 share">
                    <p className="title-tags mb-2">{t("Share This On")}</p>
                    <a data-social="share-linkedin" data-toggle="tooltip" data-placement="top" title="LinkedIn" href="#" onClick={handleShareLinkedIn}><svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.580078" width="55" height="55" rx="11.5" stroke="#5D6A85" />
                        <path d="M40 17.8448V38.3154C40 38.7834 39.8141 39.2323 39.4831 39.5632C39.1522 39.8942 38.7033 40.0801 38.2353 40.0801H17.7647C17.2967 40.0801 16.8478 39.8942 16.5169 39.5632C16.1859 39.2323 16 38.7834 16 38.3154V17.8448C16 17.3768 16.1859 16.9279 16.5169 16.5969C16.8478 16.266 17.2967 16.0801 17.7647 16.0801H38.2353C38.7033 16.0801 39.1522 16.266 39.4831 16.5969C39.8141 16.9279 40 17.3768 40 17.8448ZM23.0588 25.2565H19.5294V36.5507H23.0588V25.2565ZM23.3765 21.3742C23.3783 21.1072 23.3276 20.8425 23.2271 20.5951C23.1267 20.3478 22.9785 20.1226 22.791 19.9325C22.6036 19.7425 22.3805 19.5912 22.1346 19.4873C21.8886 19.3834 21.6246 19.329 21.3576 19.3271H21.2941C20.7512 19.3271 20.2305 19.5428 19.8466 19.9267C19.4627 20.3106 19.2471 20.8313 19.2471 21.3742C19.2471 21.9171 19.4627 22.4378 19.8466 22.8217C20.2305 23.2056 20.7512 23.4213 21.2941 23.4213C21.5611 23.4278 21.8268 23.3817 22.0759 23.2856C22.3251 23.1894 22.5529 23.0451 22.7463 22.8609C22.9396 22.6767 23.0948 22.4562 23.203 22.212C23.3111 21.9678 23.3701 21.7047 23.3765 21.4377V21.3742ZM36.4706 29.6895C36.4706 26.2942 34.3106 24.9742 32.1647 24.9742C31.4621 24.939 30.7626 25.0887 30.1358 25.4082C29.5091 25.7278 28.9771 26.2061 28.5929 26.7954H28.4941V25.2565H25.1765V36.5507H28.7059V30.5436C28.6549 29.9284 28.8487 29.3179 29.2452 28.8447C29.6417 28.3715 30.2089 28.0739 30.8235 28.0165H30.9576C32.08 28.0165 32.9129 28.7224 32.9129 30.5013V36.5507H36.4424L36.4706 29.6895Z" fill="#242E49" />
                    </svg>
                    </a>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8">
                    <div className="ex-basic-2">
                        <p className="para-blog">
                            {t("When it comes to finding the perfect")}
                        </p>
                        <img className="img-fluid border-r-15 mt-1 mb-3" src={getImagePath("blog-2.webp")} alt="alternative" />
                        <h2 className="h2-title-blog mt-6">
                            {t("The Real Estate Marketplace Mess")}
                        </h2>
                        <p className="para-blog pt-3">{t("Picture this: You're scrolling through")}</p>
                        <p className="para-blog pt-3">
                            {t("Then there is another problem that users")}
                        </p>
                        <p className="para-blog pt-3">{t("Then there are Low-quality listings")}
                        </p>
                        <p className="para-blog font-weight-700 pt-3"> {t("Thankfully, AI is here to help")}</p>

                        <h3 className="blogs-title-desc"> {t("1. Duplicate Detective")}</h3>
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <p className="para-blog pt-3">
                                    {t("AI has a talent for spotting patterns")}
                                </p>
                            </div>
                            <div className="col">
                                <img className="img-fluid border-r-15 mt-1 mb-3" src={"/assets/images/image 2365.webp"} alt="alternative" />
                            </div>
                        </div>
                        <h3 className="blogs-title-desc"> {t("2. Fake Listing Buster")}</h3>
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <p className="para-blog pt-3">
                                    {t("Fake listings can be outsmarted")}
                                </p>
                            </div>
                            <div className="col">
                                <img className="img-fluid border-r-15 mt-1 mb-3" src={"/assets/images/Rectangle 4998376.webp"} alt="alternative" />
                            </div>
                        </div>
                        <h3 className="blogs-title-desc"> {t("3. Quality Control")}</h3>
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <p className="para-blog pt-3">
                                    {t("AI dislikes blurry photos or incomplete descriptions")}
                                </p>
                            </div>
                            <div className="col">
                                <img className="img-fluid border-r-15 mt-1 mb-3" src={"/assets/images/Rectangle 9.webp"} alt="alternative" />
                            </div>
                        </div>

                      
                        <h3 className="blogs-title-desc"> {t("4. Rebuilding Trust")}</h3>
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <p className="para-blog pt-3">
                                    {t("With AI on the case, trust in the marketplace")}
                                </p>
                            </div>
                            <div className="col">
                                <img className="img-fluid border-r-15 mt-1 mb-3" src={"/assets/images/Rectangle 10.webp"} alt="alternative" />
                            </div>
                        </div>

                        <p className="para-blog pt-3 mb-4">
                            {t("Let's use AI to make real estate transactions")}
                        </p>
                        <img className="img-fluid mt-1 mb-4 border-r-15" src="/assets/images/blog2.webp" alt="alternative" />

                    </div>
                </div>
                <div className="col-md-4 privacy mt-4">
                    <div className="back-set back-set-1  ">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center">
                                <div className="text-container">
                                    <h1 className="h1-large text-center font-22">{t("blog title 2")}</h1>
                                    <p className="p-large text-center font-16">{t("blog desc 2")}</p>
                                    <div className="d-flex justify-content-center marg-2rem">
                                        <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-set back-set-2">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center">
                                <div className="text-container">
                                    <h1 className="h1-large text-center font-44">{t("increase your ROI with")}</h1>
                                    <p className="p-large text-center font-16">{t("Let’s talk to us")}</p>
                                    <div className="d-flex justify-content-center marg-2rem">
                                        <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-set back-set-4">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center">
                                <div className="text-container">
                                    <h1 className="h1-large text-center font-44">{t("increase your ROI with")}</h1>
                                    <p className="p-large text-center font-16">{t("Let’s talk to us")}</p>
                                    <div className="d-flex justify-content-center marg-2rem">
                                        <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="back-set back-set-3">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center">
                                <div className="text-container">
                                    <h1 className="h1-large text-center font-22">{t("blog title 2")}</h1>
                                    <p className="p-large text-center font-16">{t("blog desc 2")}</p>
                                    <div className="d-flex justify-content-center marg-2rem">
                                        <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BlogContact />

        <Footer />

        <Newsletter />
    </ >
    )
}
export default Blog2;