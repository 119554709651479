import { useState } from "react";
import { useTranslation } from "react-i18next";


const Benefits: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const [selectedContent, setSelectedContent] = useState<number>(1);

    const handleSelectedContent = (value: number) => {
        setSelectedContent(value);
    };

  

    return (
        <>

            <div className="basic-enh back-sect2  marg-2rem" style={{ background: "#F3F1FF" }}>
                <div className="container padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="h3-heading">{t("Benefits That Set")}  <span> {t("You Apart-1")} </span></h3>
                        </div>
                    </div>
                  
                    <div className="row dir-rtl">
                        <div className="col-lg-6 col-xl-6">
                            <div className="sidebar">
                                <ul>
                                    <li className={`marg-40 ${selectedContent === 1 ? 'active' : ''}`} onClick={() => handleSelectedContent(1)}>
                                        <h4 className="title">{t("Impress Clients")}</h4>
                                        {selectedContent === 1 && <p className="para padd-0">{t("Deliver stunning visuals that captivate")}</p>}
                                    </li>
                                    <li className={`marg-40 ${selectedContent === 2 ? 'active' : ''}`} onClick={() => handleSelectedContent(2)}>
                                        <h4 className="title">{t("Accelerate Workflows")}</h4>
                                        {selectedContent === 2 && <p className="para padd-0">{t("Save hours on manual rendering")}</p>}
                                    </li>
                                    <li className={`marg-40 ${selectedContent === 3 ? 'active' : ''}`} onClick={() => handleSelectedContent(3)}>
                                        <h4 className="title">{t("Enhance Presentations")}</h4>
                                        {selectedContent === 3 && <p className="para padd-0">{t("Stand out in proposals and presentations")}</p>}
                                    </li>
                                
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6">
                            <div className="image-container">
                            {selectedContent === 1 &&  <img className="img-fluid" src="/assets/images/Frame 1171275531-1.webp" alt="alternative" />}
                            {selectedContent === 2 &&  <img className="img-fluid" src="/assets/images/Frame 1171275531-2.webp" alt="alternative" />}
                            {selectedContent === 3 &&  <img className="img-fluid" src="/assets/images/Frame 1171275531-3.webp" alt="alternative" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default Benefits;