
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Questions: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();

    const [isShowQues1, setIsShowQues1] = useState<Boolean>(true);
    const [isShowQues2, setIsShowQues2] = useState<Boolean>(true);
    const [isShowQues3, setIsShowQues3] = useState<Boolean>(true);
    const [isShowQues4, setIsShowQues4] = useState<Boolean>(true);
    const [isShowQues5, setIsShowQues5] = useState<Boolean>(true);
    const [isShowQues6, setIsShowQues6] = useState<Boolean>(true);

    return (
        <div className="cards-questions back-sect3 marg-2rem">
            <div className="container padd-50 padd-20-bottom">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="h2-heading"> {t("Frequently Asked Questions")}</h2>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12 col-md-12">
                        <div className="card-common">
                            <div className="question-gras">
                                {t("How quickly will the photo enhancements")}
                                {isShowQues1 ? <span className="pointer-right" onClick={() => setIsShowQues1(!isShowQues1)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues1(!isShowQues1)}>
                                        <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                        </svg>
                                    </span>}
                            </div>
                            {isShowQues1 == false && <p className="question" >
                                {t("Enhancements are processed in real-time")}
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("Can I customize the enhancements")}
                                {isShowQues2 ? <span className="pointer-right" onClick={() => setIsShowQues2(!isShowQues2)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues2(!isShowQues2)}>
                                        <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                        </svg>
                                    </span>}
                            </div>
                            {isShowQues2 == false && <p className="question" >
                                {t("Yes, Coralytics allows you to tailor enhancements")}
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("Is Coralytics compatible with my current")}
                                {isShowQues3 ? <span className="pointer-right" onClick={() => setIsShowQues3(!isShowQues3)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues3(!isShowQues3)}>
                                        <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                        </svg>
                                    </span>}
                            </div>
                            {isShowQues3 == false && <p className="question" >
                                {t("Yes, Coralytics is API-enabled")}
                                    </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("Can I preview the enhancements")}
                                {isShowQues4 ? <span className="pointer-right" onClick={() => setIsShowQues4(!isShowQues4)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues4(!isShowQues4)}>
                                        <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                        </svg>
                                    </span>}
                            </div>
                            {isShowQues4 == false && <p className="question" >
                               {t("Absolutely! You can preview all enhancements")}
                                       </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("What happens if i don’t like")}
                                {isShowQues5 ? <span className="pointer-right" onClick={() => setIsShowQues5(!isShowQues5)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues5(!isShowQues5)}>
                                        <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                        </svg>
                                    </span>}
                            </div>
                            {isShowQues5 == false && <p className="question" >
                               {t("You can easily make changes or redo")}

                            </p>}
                        </div>
                 
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Questions;