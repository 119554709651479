import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import GetStarted from "./home/get-started";
import BlogContact from "./blogs/blog-contact";
import Footer from "./home/footer";
import Newsletter from "./blogs/newsletter";



const PrivacyPolicy: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("CORALYTICS Privacy Policy")}</title>
                <meta name="description" content={t("We at CORALYTICS, a platform owned")} />
            </Helmet>

            <header id="header" className="header page-blogs  dir-rtl">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="text-container">
                                <h1 className="h1-large text-center ">{t("CORALYTICS Privacy Policy")}</h1>
                                <p className="p-large text-center ">{t("We at CORALYTICS, a platform owned")}</p>
                                <div className="d-flex justify-content-center mb-4">
                                    <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container dir-rtl ">
                <div className="row">
                    <div className="col-md-8">
                        <div className="ex-basic-2">
                            <h3 className="blogs-title-desc mt-2"> {t("1. Introduction")}</h3>
                            <p className="para-blog pt-3">
                                {t("We at CORALYTICS, a platform owned")}
                            </p>
                            <h3 className="blogs-title-desc mt-6">{t("2. Information We Collect")}</h3>
                            <p className="para-blog pt-3">
                                {t("We collect various types of personal")}
                            </p>
                            <ul>
                                <li className="para-blog mb-3"><strong className="font-weight-600">{t("Personally Identifiable")}</strong>
                                    {t("This includes information that can be")}
                                </li>
                                <li className="para-blog mb-3"><strong className="font-weight-600">{t("Non-Personally Identifiable")}</strong>
                                    {t("This includes information that cannot")}
                                </li>
                            </ul>

                            <h3 className="blogs-title-desc mt-6">{t("3. How We Use Your Information")}</h3>
                            <p className="para-blog pt-3">
                                {t("We use your personal information")}
                            </p>

                            <ul>
                                <li className="para-blog mb-3">
                                    {t("To provide and operate the Services")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("To personalize your experience")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("To send you important information")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("To respond to your inquiries")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("To improve the Services")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("To analyze how you use the")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("To comply with legal requirements")}
                                </li>
                            </ul>

                            <h3 className="blogs-title-desc mt-6">{t("4. How We Share Your Information")}</h3>

                            <p className="para-blog pt-3">
                                {t("We may share your personal information with")}
                            </p>
                            <p className="para-blog pt-3">
                                {t("We may also disclose your personal information")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("5. Your Choices")}</h3>
                            <p className="para-blog pt-3">
                                {t("You have certain choices regarding")}
                            </p>
                            <ul>
                                <li className="para-blog mb-3">
                                    {t("You can access and update your")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("You can unsubscribe from marketing")}
                                </li>
                                <li className="para-blog mb-3"> {t("You can delete your account")} <a className="a-email"
                                    href="mailto: [info@coralytics.com]"> info@coralytics.com</a></li>
                            </ul>

                            <h3 className="blogs-title-desc mt-6">{t("6. Data Security")}</h3>
                            <p className="para-blog pt-3">
                                {t("We take reasonable measures to protect")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("7. International Transfers")}</h3>
                            <p className="para-blog pt-3">
                                {t("Your personal information may be transferred")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("8. Children's Privacy")}</h3>
                            <p className="para-blog pt-3">
                                {t("The Services are not intended for")}
                                <a className="a-email" href="mailto: [info@coralytics.com]"> info@coralytics.com</a>. {t("We will take steps to remove")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("9. Changes to this Policy")}</h3>
                            <p className="para-blog pt-3">
                                {t("We may update this Policy from time")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("10. Contact Us")}</h3>
                            <p className="para-blog pt-3">
                                {t("If you have any questions about")}
                                <a className="a-email"
                                    href="mailto: [info@coralytics.com]"> info@coralytics.com</a>
                            </p>

                            <p className="para-blog pt-3 mb-4">
                                <strong className="font-weight-600">{t("Date of Last Update:")}</strong> {t("December 25, 2023")}</p>

                        </div>
                    </div>
                    <div className="col-md-4 privacy">
                        <div className="back-set back-set-1">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-22">{t("CORALYTICS Privacy Policy")}</h1>
                                        <p className="p-large text-center font-16">{t("We at CORALYTICS, a platform owned")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-set back-set-2">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-44">{t("increase your ROI with")}</h1>
                                        <p className="p-large text-center font-16">{t("Let’s talk to us")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-set back-set-4">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-44">{t("increase your ROI with")}</h1>
                                        <p className="p-large text-center font-16">{t("Let’s talk to us")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-set back-set-3">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-22">{t("CORALYTICS Privacy Policy")}</h1>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <BlogContact />

            <Footer />

            <Newsletter />
        </>
    )
}
export default PrivacyPolicy;