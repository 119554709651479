import { useTranslation } from "react-i18next";
import Section2 from "../home/section-2";
import Features from "./Features";
import Section4 from "../home/section-4";
import Testimonials from "./Testimonials";
import Footer from "../home/footer";
import FormContact from "../Contact Us/form-contact";
import { Helmet } from "react-helmet-async";
import GetStarted from "../home/get-started";



const Marketplace: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };


    return (
        <>
            <Helmet>
                <title>{t("Marketplace")}</title>
                <meta name="description" content={t("Revolutionize your online marketplace with")} />
            </Helmet>

            <div className=" page-generate page-enhance height-mark ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="text-container">
                                <div className="section-butt  d-flex justify-content-center">
                                    <span className="section-title-AI ">
                                        <svg width="24" height="25" className="marg10" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_135_2592" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                                                <path d="M24 0.679688H0V24.6797H24V0.679688Z" fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_135_2592)">
                                                <path d="M8 16.6797C8 17.2319 8.44771 17.6797 9 17.6797C9.55229 17.6797 10 17.2319 10 16.6797C10 14.3713 10.5108 12.9373 11.3842 12.0639C12.2575 11.1905 13.6915 10.6797 16 10.6797C16.5522 10.6797 16.9999 10.232 16.9999 9.67969C16.9999 9.12741 16.5522 8.67969 16 8.67969C13.6915 8.67969 12.2575 8.16887 11.3842 7.2955C10.5108 6.42212 10 4.98818 10 2.67969C10 2.1274 9.55229 1.67969 9 1.67969C8.44771 1.67969 8 2.1274 8 2.67969C8 4.98818 7.48919 6.42212 6.6158 7.2955C5.74243 8.16887 4.30848 8.67969 2 8.67969C1.44772 8.67969 1 9.12741 1 9.67969C1 10.232 1.44772 10.6797 2 10.6797C4.30848 10.6797 5.74243 11.1905 6.6158 12.0639C7.48919 12.9373 8 14.3713 8 16.6797Z" fill="#573CFF" />
                                                <path d="M16.5 22.6796C16.5 23.232 16.9477 23.6797 17.5 23.6797C18.0523 23.6797 18.5 23.232 18.5 22.6796C18.5 21.2393 18.8198 20.4304 19.2852 19.9649C19.7507 19.4995 20.5596 19.1797 22 19.1797C22.5523 19.1797 23 18.732 23 18.1796C23 17.6274 22.5523 17.1797 22 17.1797C20.5596 17.1797 19.7507 16.8599 19.2852 16.3944C18.8198 15.929 18.5 15.1201 18.5 13.6796C18.5 13.1274 18.0523 12.6797 17.5 12.6797C16.9477 12.6797 16.5 13.1274 16.5 13.6796C16.5 15.1201 16.1802 15.929 15.7147 16.3944C15.2494 16.8599 14.4404 17.1797 13 17.1797C12.4477 17.1797 12 17.6274 12 18.1796C12 18.732 12.4477 19.1797 13 19.1797C14.4404 19.1797 15.2494 19.4995 15.7147 19.9649C16.1802 20.4304 16.5 21.2393 16.5 22.6796Z" fill="#573CFF" />
                                            </g>
                                        </svg>
                                        {t("Marketplace")}
                                    </span>
                                </div>
                                <h1 className="h1-large text-center">{t("Transform Your Marketplace with Coralytics")}</h1>
                                <h2 className="enh-h2">{t("Automate Moderation. Enhance Listings")}</h2>
                                <p className="p-large text-center">{t("Revolutionize your online marketplace with")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12 d-flex justify-content-center ">
                            <img className="img-g" src={"/assets/images/Group 1410087827-web.webp"} alt="alternative" />
                            <img className="img-g-mobile" src={"/assets/images/Group 1410087827-mobile.webp"} alt="alternative" />

                        </div>
                    </div>
                </div>
            </div>

            {/* Real estate agents and agencies rely on Coralytics*/}
            <Section2 />

            {/* Powerful Features Tailored for Marketplaces */}
            <Features />

            <Section4 />

            {/* Benefits That Drive Marketplace Success*/}
            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Benefits That Drive")}<span> {t("Marketplace Success")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="32.3012" cy="31.8012" r="31.8012" fill="#746AFF" />
                                        <path d="M30.4091 14.8691C21.891 15.8108 15.2656 23.0324 15.2656 31.8015C15.2656 41.2105 22.8931 48.8379 32.302 48.8379C41.0711 48.8379 48.2928 42.2126 49.2344 33.6945H30.4091V14.8691Z" stroke="white" strokeWidth="3.40728" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M48.3689 26.1228H37.9808V15.7349C42.8214 17.4458 46.6579 21.2822 48.3689 26.1228Z" stroke="white" strokeWidth="3.40728" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Enhance User Trust")} </div>
                                    <p className="testimonial-text"> {t("Maintain a safe, reliable platform")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="32.4731" cy="31.8012" r="31.8012" fill="#EF5E4E" />
                                        <path d="M24.9022 33.6936V31.8007M32.4739 33.6936V28.0148M40.0456 33.6936V24.229M24.9022 48.8371L32.4739 41.2653L40.0456 48.8371M15.4375 16.6572H49.5103M17.3304 16.6572H47.6173V39.3724C47.6173 40.4178 46.7698 41.2653 45.7244 41.2653H19.2234C18.1779 41.2653 17.3304 40.4178 17.3304 39.3724V16.6572Z" stroke="white" strokeWidth="3.40728" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Boost Operational Efficiency")} </div>
                                    <p className="testimonial-text"> {t("Automate over 90% of content moderation")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="32.3012" cy="32.4897" r="31.8012" fill="#F64D00" />
                                        <path d="M49.3384 49.5981L37.9808 38.2406M41.7667 28.7759C41.7667 36.094 35.8342 42.0264 28.5161 42.0264C21.1981 42.0264 15.2656 36.094 15.2656 28.7759C15.2656 21.4578 21.1981 15.5254 28.5161 15.5254C35.8342 15.5254 41.7667 21.4578 41.7667 28.7759Z" stroke="white" strokeWidth="3.40728" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Increase Engagement")} </div>
                                    <p className="testimonial-text"> {t("High-quality, compliant listings")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="32.4731" cy="32.4897" r="31.8012" fill="#00BB8F" />
                                        <path d="M32.4755 21.1316V17.3457M32.4755 21.1316C30.3846 21.1316 28.6896 22.8266 28.6896 24.9174C28.6896 27.0083 30.3846 28.7033 32.4755 28.7033M32.4755 21.1316C34.5664 21.1316 36.2613 22.8266 36.2613 24.9174C36.2613 27.0083 34.5664 28.7033 32.4755 28.7033M21.1179 43.8467C23.2088 43.8467 24.9038 42.1517 24.9038 40.0609C24.9038 37.97 23.2088 36.275 21.1179 36.275M21.1179 43.8467C19.027 43.8467 17.332 42.1517 17.332 40.0609C17.332 37.97 19.027 36.275 21.1179 36.275M21.1179 43.8467V47.6326M21.1179 36.275V17.3457M32.4755 28.7033V47.6326M43.8331 43.8467C45.9239 43.8467 47.6189 42.1517 47.6189 40.0609C47.6189 37.97 45.9239 36.275 43.8331 36.275M43.8331 43.8467C41.7422 43.8467 40.0472 42.1517 40.0472 40.0609C40.0472 37.97 41.7422 36.275 43.8331 36.275M43.8331 43.8467V47.6326M43.8331 36.275V17.3457" stroke="white" strokeWidth="3.40728" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Scale Seamlessly")} </div>
                                    <p className="testimonial-text"> {t("Coralytics AI-powered tools grow")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* How Coralytics Integrates with Your Marketplace*/}
            <div className="basic-Benefits back-242">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1 marg-0">{t("How Coralytics Integrates with")}<span> {t("Your Marketplace")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl mar-top-50">
                        <div className="col-md-3 mb-3">
                            <div className="card-solution-1">
                                <div className="testimonial-author  d-flex justify-content-start"> {t("Easy API Integration")} </div>
                                <p className="testimonial-text  text-start"> {t("Seamlessly connect Coralytics to your")} </p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card-solution-1">
                                <div className="testimonial-author  d-flex justify-content-start"> {t("Automated Processing")} </div>
                                <p className="testimonial-text  text-start"> {t("Let Coralytics analyze and moderate listings")} </p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card-solution-1">
                                <div className="testimonial-author  d-flex justify-content-start"> {t("Insightful Analytics")} </div>
                                <p className="testimonial-text  text-start"> {t("Monitor performance and make data-driven")} </p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card-solution-1">
                                <div className="testimonial-author  d-flex justify-content-start"> {t("Continuous Improvement")} </div>
                                <p className="testimonial-text  text-start"> {t("Benefit from ongoing AI advancements")} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Testimonials />

            {/*Ready to Elevate Your Marketplace?*/}
            <div className='basic-12 marg-2rem back-basic-12'>
                <div className="container">
                    <div className="card">
                        <div className='row d-flex justify-content-center align-items-center align-self-center'>
                            <div className='col-lg-12 col-xl-12 '>
                                <h1> {t("Ready to Elevate Your Marketplace?")} </h1>
                                <h1 className="h1-font-32"> {t("Ready to Elevate Your Marketplace?")} </h1>
                                <p> {t("Join the ranks of successful marketplaces")}</p>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                        <GetStarted Style="btn-solid-lg" Value={`${t("Integrate Coralytics Now")}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )

}
export default Marketplace;