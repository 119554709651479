import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import { useTranslation } from "react-i18next";


const ListingVideo: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();


    return (
        <>
            {/* Virtual Staging */}
            <div className="basic-1 marg-2rem padd-2rem">
                <div className="container padd-50">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-xl-7 card-order-2 mb-3">
                            <div className="image-container">
                                <img className="img-fluid" src="/assets/images/Frame 2147224926.webp" alt="alternative" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-5 card-order-1 mb-4">
                            <div className="text-container">
                                <div className="section-butt">
                                    <span className="section-title">
                                        <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                            <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                        </svg>{t("Virtual Staging")}
                                    </span>
                                </div>
                                <h2>{t("Instant garden landscaping with 0 effort")}</h2>
                                <p>{t("Enhance your property's outdoor")}</p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/*Listing Video */}
            <div className="basic-5 marg-2rem padd-2rem" >
                <div className="container padd-50">
                    <div className="row">
                        <div className="col-lg-5 col-xl-5 card-order-1 mb-4  align-items-center">
                            <div className="text-container marg-left-30">
                                <div className="section-butt">
                                    <span className="section-title">
                                        <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                            <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                        </svg>
                                        {t("Listing Video")}
                                    </span>
                                </div>
                                <h2>{t("Generate engaging listing")}</h2>
                                <p>{t("Create engaging listing videos")}</p>

                                <div className="row mb-4 dir-rtl">
                                    <div className="col-md-4 col-6">
                                        <div className="tags img-listing-1">
                                            <span>
                                                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_2040_1805)">
                                                        <path d="M29.5124 16.349C29.5124 22.6344 29.5124 25.777 27.5597 27.7297C25.6071 29.6823 22.4644 29.6823 16.179 29.6823C9.89364 29.6823 6.75095 29.6823 4.79832 27.7297C2.8457 25.777 2.8457 22.6344 2.8457 16.349C2.8457 10.0636 2.8457 6.92087 4.79832 4.96825C6.75095 3.01562 9.89364 3.01562 16.179 3.01562" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                                        <path d="M2.8457 17.0132L5.18115 14.9697C6.39618 13.9066 8.2274 13.9675 9.36902 15.1092L15.0887 20.8288C16.005 21.7451 17.4474 21.87 18.5076 21.1249L18.9052 20.8455C20.4308 19.7733 22.4949 19.8975 23.881 21.145L28.179 25.0132" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                                        <path d="M17.0536 17.3477H14.7514L19.9242 2.97332H22.4299L27.6027 17.3477H25.3006L21.2367 5.58429H21.1244L17.0536 17.3477ZM17.4396 11.7186H24.9075V13.5435H17.4396V11.7186Z" fill="#040404" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2040_1805">
                                                            <rect width="36" height="36" fill="white" transform="translate(0.179688 0.347656)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <p>{t("Image Auto")}<br/>{t("Editing")}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="tags img-listing-1">
                                            <span>
                                                
                                                <img src="/assets/images/Frame-11.svg" className="height-46" alt="tags"/>

                                            </span>
                                            <p>{t("Description")}<br/>{t("Generation")}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="tags img-listing-1">
                                            <span>
                                                
                                                <img src="/assets/images/Frame-12.webp" className="height-46" alt="tags"/>

                                            </span>
                                            <p>{t("Title")}<br/>{t("Generation-1")}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <div className="tags img-listing-1">
                                            <span>
                                             
                                                <img src="/assets/images/Frame-13.svg" className="height-46" alt="tags"/>

                                            </span>
                                            <p>{t("listing conversion")}<br/>{t("optimization")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-7 col-xl-7 card-order-2">
                            <div className="card marg-video">
                                <video autoPlay muted playsInline>
                                    <source src="/assets/images/serv-4.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 3D Floor plans */}
            <div className="basic-1 marg-2rem padd-2rem">
                <img className="decoration-circles-6" src="/assets/images/Group 52.svg" alt="alternative" />
                <div className="container padd-50">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6 card-order-2">
                            <div className="image-container">
                                <ReactCompareSlider
                                    itemOne={<ReactCompareSliderImage src="/assets/images/plan-2d.webp" alt="Image one" style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />}
                                    itemTwo={<ReactCompareSliderImage src="/assets/images/plan-3d.webp" alt="Image two" style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 card-order-1 mb-4">
                            <div className="text-container">
                                <div className="section-butt">
                                    <span className="section-title">
                                        <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                            <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                        </svg>{t("3D Floor plans")}
                                    </span>
                                </div>
                                <h2>{t("3D Floor Plan Transformation")}</h2>
                                <p>{t("Bring your floor plans to life")}</p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default ListingVideo;