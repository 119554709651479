import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { PopupModal } from "react-calendly";


 
const GetStarted: React.FunctionComponent<{ Style: any, Value: any }> = (props) => {

    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (<>
        <button className={props.Style} onClick={handleClickOpen} >{props.Value}</button>

        {open && (
            <PopupModal
                url="https://calendly.com/operations-coralytics/30min"
                pageSettings={{
                    backgroundColor: 'ffffff',
                    hideLandingPageDetails: true,
                    primaryColor: '573CFF',
                    textColor: '000000',
                }}
                prefill={{
                    email: "",
                    name: "",
                }}
                rootElement={document.getElementById("root") || document.body}
                onModalClose={handleClose}
                open={open}
            />
        )}
    </>
    )

}
export default GetStarted;