import { useState } from "react";
import { useTranslation } from "react-i18next";


const Section1: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const [selectedContent, setSelectedContent] = useState<number>(1);

    const handleSelectedContent = (value: number) => {
        setSelectedContent(value);
    };

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>

         
            <div className="basic-enh back-sect2  marg-2rem">
                <div className="container padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="h3-heading">{t("Benefits for")}  <span> {t("Marketplaces?")} </span></h3>
                            <p className="p-heading-1">{t("Elevate your marketplace")}</p>
                        </div>
                    </div>
                    <div className="row mt-enh dir-rtl">
                        <div className="col-md-6 ">
                        <div className="section-butt">
                            <span className="section-title">
                                <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                    <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                </svg>{t("Benefits")}
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-lg-6 col-xl-6">
                            <div className="sidebar">
                                <ul>
                                    <li className={`marg-40 ${selectedContent === 1 ? 'active' : ''}`} onClick={() => handleSelectedContent(1)}>
                                        <h6 className="title">{t("Ensure Listing Quality")}</h6>
                                        {selectedContent === 1 && <p className="para padd-0">{t("Automatic checks align every listing")}</p>}
                                    </li>
                                    <li className={`marg-40 ${selectedContent === 2 ? 'active' : ''}`} onClick={() => handleSelectedContent(2)}>
                                        <h6 className="title">{t("Prevent Clutter")}</h6>
                                        {selectedContent === 2 && <p className="para padd-0">{t("Instantly identify and remove duplicate listings")}</p>}
                                    </li>
                                    <li className={`marg-40 ${selectedContent === 3 ? 'active' : ''}`} onClick={() => handleSelectedContent(3)}>
                                        <h6 className="title">{t("Improve Search Rankings")}</h6>
                                        {selectedContent === 3 && <p className="para padd-0">{t("High-quality, unique listings boost SEO")}</p>}
                                    </li>
                                    <li className={`marg-40 ${selectedContent === 4 ? 'active' : ''}`} onClick={() => handleSelectedContent(4)}>
                                        <h6 className="title">{t("Reduce Manual Work")}</h6>
                                        {selectedContent === 4 && <p className="para padd-0">{t("No more manual checks—CORA’s")}</p>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6">
                            <div className="image-container">
                            {selectedContent === 1 &&  <img className="img-fluid" src={"/assets/images/Frame-1171275532_1.webp"} alt="alternative" />}
                            {selectedContent === 2 &&  <img className="img-fluid" src={getImagePath("Frame 1171275532-1.webp")} alt="alternative" />}
                            {selectedContent === 3 &&  <img className="img-fluid" src={"/assets/images/Frame-2147224760_1.webp"} alt="alternative" />}
                            {selectedContent === 4 &&  <img className="img-fluid" src={"/assets/images/robot-and-woman-working.webp"} alt="alternative" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default Section1;