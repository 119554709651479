import { useTranslation } from "react-i18next";
import GetStarted from "./get-started";

const Section7: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();

    return (
        <>
            {/* Boost your return on marketing */}
            <div className='basic-12 marg-2rem back-12'>
                <div className="container">
                    <div className="card">
                        <div className='row d-flex justify-content-center align-items-center align-self-center'>
                            <div className='col-lg-12 col-xl-12 '>
                                <h1> {t("Boost your return on marketing")} </h1>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                        <GetStarted Style={"btn-solid-lg"} Value={`${t("Request Free Demo")}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Section7;