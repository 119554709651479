import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import GetStarted from "./home/get-started";
import BlogContact from "./blogs/blog-contact";
import Footer from "./home/footer";
import Newsletter from "./blogs/newsletter";



const AboutUs: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("About us")}</title>
                <meta name="description" content={t("Welcome to our company, where we offer")} />
            </Helmet>

            <header id="header" className="header page-blogs  dir-rtl">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="text-container">
                                <h1 className="h1-large text-center ">{t("About us")}</h1>
                                <p className="p-large text-center ">{t("Welcome to our company, where we offer")}</p>
                                <div className="d-flex justify-content-center mb-4">
                                    <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container dir-rtl ">
                <div className="row">
                    <div className="col-md-8">
                        <div className="ex-basic-2">
                            <p className="para-blog pt-4">
                            {t("Welcome to our company, where we offer")}
                            </p>
                            <h3 className="blogs-title-desc mt-6">{t("Moderate")}</h3>
                            <p className="para-blog pt-3">
                                {t("Say goodbye to manual content moderation")}
                            </p>
                        
                            <h3 className="blogs-title-desc mt-6">{t("Understand")}</h3>
                            <p className="para-blog pt-3">
                                {t("Unlock the full potential of your image")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("Enhance")}</h3>
                            <p className="para-blog pt-3">
                                {t("Boost audience engagement and drive")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("Generate")}</h3>
                            <p className="para-blog pt-3">
                                {t("Need top-notch text and visual content?")}
                            </p>
                        
                            <p className="para-blog pt-3 mb-4">
                               {t("With our diverse range of solutions")}</p>

                        </div>
                    </div>
                    <div className="col-md-4 privacy">
                        <div className="back-set back-set-1">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-22">{t("About us")}</h1>
                                        <p className="p-large text-center font-16">{t("Welcome to our company, where we offer")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-set back-set-2">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-44">{t("increase your ROI with")}</h1>
                                        <p className="p-large text-center font-16">{t("Let’s talk to us")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div >

            <BlogContact />

            <Footer />

            <Newsletter />
        </>
    )
}
export default AboutUs;