import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NewsletterServ } from "../../services/serv";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Newsletter: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [open, setOpen] = useState(false);
    const [colorMsg, setColorMsg] = useState<any>();
    const [message, setMessage] = useState<any>();


    const validateEmail = (email: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: any) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        if (!validateEmail(newEmail)) {
            setEmailError('Invalid email address');
            setIsEmailValid(false);
        } else {
            setEmailError('');
            setIsEmailValid(true);
        }
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const Subscribe = () => {

        NewsletterServ(email).then((data: any) => {
            setEmail("");
            setColorMsg("success");
            setOpen(true);
            setMessage(t("Successfully subscribed"));
        }).catch((error: any) => {
            setEmail("");
            setColorMsg("error");
            setOpen(true);
            setMessage(t("Successfully failed"))
        })
    }
    return (<>
        <div className="basic-8 padd-mobile" style={{ paddingBottom: "0px" }}>
            <div className="container">
                <div className="row line">
                    <div className="col-lg-12 col-xl-12">
                        <h1> {t("Newsletter")} </h1>
                        <p className="marg-r-l">{t("Be the first one")} </p>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                <div className="form-group">
                                <input type="email" className="input-basic-8" placeholder={t("Enter your email")} value={email} onChange={handleEmailChange} />
                                {isEmailValid==false && <p className="error">{emailError}</p>}
                                </div>
                                <button className="butt-started newletter-but" style={{ marginTop: "0px" ,height:"64px"}} onClick={Subscribe}>{t("Subscribe")}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={colorMsg}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    </>
    )

}
export default Newsletter;