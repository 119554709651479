import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import GetStarted from "./home/get-started";
import BlogContact from "./blogs/blog-contact";
import Footer from "./home/footer";
import Newsletter from "./blogs/newsletter";



const TermsConditions: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("CORALYTICS Terms and Conditions")}</title>
                <meta name="description" content={t("CWe at CORALYTICS, a platform owned")} />
            </Helmet>

            <header id="header" className="header page-blogs  dir-rtl">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="text-container">
                                <h1 className="h1-large text-center ">{t("CORALYTICS Terms and Conditions")}</h1>
                                <p className="p-large text-center ">{t("We at CORALYTICS, a platform owned")}</p>
                                <div className="d-flex justify-content-center mb-4">
                                    <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container dir-rtl ">
                <div className="row">
                    <div className="col-md-8">
                        <div className="ex-basic-2">
                            <h3 className="blogs-title-desc mt-2"> {t("1. Introduction")}</h3>
                            <p className="para-blog pt-3">
                                {t("We at CORALYTICS, a platform owned")}
                            </p>
                            <h3 className="blogs-title-desc mt-6">{t("2. Acceptance of Terms")}</h3>
                            <p className="para-blog pt-3">
                                {t("By accessing or using the Services")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("3. Account Registration and Use")}</h3>
                            <p className="para-blog pt-3">
                                {t("To access certain features of the Services")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("4. User Content")}</h3>
                            <p className="para-blog pt-3">
                                {t("The Services may allow you to submit")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("5. Prohibited Content and Activities")}</h3>
                            <ul>
                                <li className="para-blog mb-3 mt-4">
                                    {t("Uploading, posting, or transmitting")}
                                </li>
                                <li className="para-blog mb-3">
                                    {t("Impersonating any person or entity")}
                                </li>
                                <li className="para-blog mb-3"> {t("Interfering with or disrupting")} </li>
                                <li className="para-blog mb-3"> {t("Using the Services to exploit")} </li>
                                <li className="para-blog mb-3"> {t("Violating any intellectual property")} </li>
                                <li className="para-blog mb-3"> {t("Attempting to gain unauthorized")} </li>
                            </ul>
                            <p className="para-blog pt-3">
                                {t("We reserve the right to terminate")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("6. Intellectual Property")}</h3>
                            <p className="para-blog pt-3">
                                {t("The Services and their content")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("7. Disclaimer of Warranties")}</h3>
                            <p className="para-blog pt-3">
                                {t("The Services are provided as")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("8. Limitation of Liability")}</h3>
                            <p className="para-blog pt-3">
                                {t("To the maximum extent permitted by law")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("9. Indemnification")}</h3>
                            <p className="para-blog pt-3">
                                {t("You agree to indemnify and hold us harmless")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("10. Governing Law and Dispute")}</h3>
                            <p className="para-blog pt-3">
                                {t("These Terms shall be governed by and construed")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("11. Entire Agreement")}</h3>
                            <p className="para-blog pt-3">
                                {t("These Terms constitute the entire agreement")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("12. Amendments")}</h3>
                            <p className="para-blog pt-3">
                                {t("We reserve the right to amend these Terms")}
                            </p>

                            <h3 className="blogs-title-desc mt-6">{t("13. Contact Us")}</h3>
                            <p className="para-blog pt-3">
                                {t("If you have any questions about these")}
                                <a className="a-email"
                                    href="mailto: [info@coralytics.com]"> info@coralytics.com</a>
                            </p>

                            <p className="para-blog pt-3 mb-4">
                                <strong className="font-weight-600">{t("Date of Last Update:")}</strong> {t("December 25, 2023")}</p>

                        </div>
                    </div>
                    <div className="col-md-4 privacy">
                        <div className="back-set back-set-1 ">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-22">{t("CORALYTICS Terms and Conditions")}</h1>
                                        <p className="p-large text-center font-16">{t("We at CORALYTICS, a platform owned")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-set back-set-2">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-44">{t("increase your ROI with")}</h1>
                                        <p className="p-large text-center font-16">{t("Let’s talk to us")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-set back-set-4">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-44">{t("increase your ROI with")}</h1>
                                        <p className="p-large text-center font-16">{t("Let’s talk to us")}</p>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-set back-set-3">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="text-container">
                                        <h1 className="h1-large text-center font-22">{t("CORALYTICS Terms and Conditions")}</h1>
                                        <div className="d-flex justify-content-center marg-2rem">
                                            <GetStarted Style={"btn-solid-lg "} Value={`${t("Get Started")}`} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <BlogContact />

            <Footer />

            <Newsletter />
        </>
    )
}
export default TermsConditions;