import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SnackbarCloseReason, Snackbar, Alert } from "@mui/material";
import { Contact } from "../../services/serv";

const BlogContact: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [industries, setIndustries]=useState<any>();
    const [phone, setPhone] = useState('');
    const [messageValue, setMessageValue] = useState('');


    const [open, setOpen] = useState<any>(false);
    const [color, setColor] = useState<any>();
    const [message, setMessage] = useState<any>();
    const [error, setError] = useState('');
    const [formError, setFormError] = useState({
        name: false,
        email: false,
        industries: false,
        phone: false,
        message: false
    });

    const validateEmail = (email: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        resetFormErrors();

        const isValid = validateForm();
        if (!isValid) return;

        submitForm();
    };

    const resetFormErrors = () => {
        setFormError({
            name: false,
            email: false,
            industries: false,
            phone: false,
            message: false
        });
    };

    const validateForm = () => {
        let valid = true;

        if (!name) {
            setFormError((prevState) => ({ ...prevState, name: true }));
            valid = false;
        }

        if (!email) {
            setFormError((prevState) => ({ ...prevState, email: true }));
            valid = false;
        } else if (!validateEmail(email)) {
            setError('Invalid email address');
            valid = false;
        }

        if (!industries) {
            setFormError((prevState) => ({ ...prevState, industries: true }));
            valid = false;
        }

        if (!phone) {
            setFormError((prevState) => ({ ...prevState, phone: true }));
            valid = false;
        }

        if (!message) {
            setFormError((prevState) => ({ ...prevState, messageValue: true }));
            valid = false;
        }

        return valid;
    };

    const submitForm = () => {
        setError('');
        Contact({ name, company_email: email, message: messageValue })
            .then((resp) => {
                handleSuccess();
            })
            .catch((error) => {
                handleError();
            });
    };

    const handleSuccess = () => {
        setColor("success");
        setOpen(true);
        setMessage("Congratulations. Your message has been sent successfully");
    };

    const handleError = () => {
        setColor("error");
        setOpen(true);
        setMessage("Error, please retry. Your message has not been sent");
    };

    return (
        <>
            <div className="back-contact-1 dir-rtl">
                <div className="row align-items-center  justify-content-center" >
                    <h3 className="title-h3 mb-4">{t("Contact Us")}</h3>
                    <div className="col-md-12 ">
                        <div className="card" >
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-6 form-group mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">{t("Name")}</label>
                                            <input type="text" className={`form-control input ${formError.name ? 'error-border' : ''}`} placeholder={t("Full Name")} aria-label="Username" aria-describedby="basic-addon1" value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            {formError.name && <p className="error">{t("Name is required")}</p>}
                                        </div>
                                        <div className="col-6 form-group mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">{t("Phone Number")}</label>

                                            <input type="phone" className={`form-control input ${formError.phone ? 'error-border' : ''}`} placeholder={t("Phone Number")} aria-label="Username" aria-describedby="basic-addon1" value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />

                                            {formError.phone && <p className="error">{t("Phone number is required")}</p>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 form-group mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">{t("Company Email")}</label>

                                            <input type="email" className={`form-control input ${formError.email ? 'error-border' : ''}`} placeholder={t("Email")} aria-label="Username" aria-describedby="basic-addon1" value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {formError.email && <p className="error">{t("Email is required")}</p>}
                                            {error && <p className="error">{error}</p>}
                                        </div>
                                        <div className="col-6 form-group  mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">{t("Industries")}</label>

                                            <input type="text" className={`form-control input ${formError.industries ? 'error-border' : ''}`} placeholder={t("Industries")} aria-label="Username" aria-describedby="basic-addon1" value={industries}
                                                onChange={(e) => setIndustries(e.target.value)}
                                            />
                                            {formError.industries && <p className="error">{t("Industries Name is required")}</p>}
                                        </div>

                                    </div>
                                    <div className="col-md-12 form-group mb-3">
                                        <label htmlFor="formGroupExampleInput" className="form-label">{t("Message")}</label>
                                        <textarea className={`form-control textarea ${formError.message ? 'error-border' : ''}`} placeholder={t("Write message")} aria-label="Username" aria-describedby="basic-addon1" value={messageValue}
                                            onChange={(e) => setMessageValue(e.target.value)}
                                        />
                                        {formError.message && <p className="error">{t("Message is required")}</p>}
                                    </div>
                                    <div className="d-flex  justify-content-center">
                                        <button className="button" onClick={handleSubmit}>{t("Send Message")}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={color}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default BlogContact;