import { useState } from "react";
import { useTranslation } from "react-i18next";


const Features: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const [selectedContent, setSelectedContent] = useState<number>(1);

    const handleSelectedContent = (value: number) => {
        setSelectedContent(value);
    };

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>

            {/* Powerful Features Tailored for Marketplaces*/}
            <div className="basic-enh back-sect2  marg-2rem">
                <div className="container padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="h3-heading">{t("Powerful Features Tailored")}  <br /><span> {t("for Marketplaces")} </span></h3>
                        </div>
                    </div>
                    <div className="row mt-enh dir-rtl">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-Moderation-tab" data-bs-toggle="pill" data-bs-target="#pills-Moderation" type="button" role="tab" aria-controls="pills-Moderation" aria-selected="true" onClick={() => handleSelectedContent(1)}>{t("Automated Listing Moderation")}</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-Enhancement-tab" data-bs-toggle="pill" data-bs-target="#pills-Enhancement" type="button" role="tab" aria-controls="pills-Enhancement" aria-selected="false" onClick={() => handleSelectedContent(4)}>{t("Real-Time Content Validation")}</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-Engagement-tab" data-bs-toggle="pill" data-bs-target="#pills-Engagement" type="button" role="tab" aria-controls="pills-Engagement" aria-selected="false" onClick={() => handleSelectedContent(7)}>{t("User Engagement Boost")}</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-Moderation" role="tabpanel" aria-labelledby="pills-Moderation-tab">
                                <div className="row dir-rtl align-items-center">
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="sidebar">
                                            <ul>
                                                <li className={`marg-40 ${selectedContent === 1 ? 'active' : ''}`} onClick={() => handleSelectedContent(1)}>
                                                    <h6 className="title">{t("Real-Time Content Validation")}</h6>
                                                    {selectedContent === 1 && <p className="para padd-0">{t("Instantly assess and validate listings for accuracy")}</p>}
                                                </li>
                                                <li className={`marg-40 ${selectedContent === 2 ? 'active' : ''}`} onClick={() => handleSelectedContent(2)}>
                                                    <h6 className="title">{t("Harmful Content Removal")}</h6>
                                                    {selectedContent === 2 && <p className="para padd-0">{t("Automatically detect and eliminate inappropriate")}</p>}
                                                </li>
                                                <li className={`marg-40 ${selectedContent === 3 ? 'active' : ''}`} onClick={() => handleSelectedContent(3)}>
                                                    <h6 className="title">{t("Fake Listing Detection")}</h6>
                                                    {selectedContent === 3 && <p className="para padd-0">{t("Leverage advanced AI to identify")}</p>}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="image-container">
                                            {selectedContent === 1 && <img className="img-fluid" src={getImagePath("Frame-1171275.webp")} alt="alternative" />}
                                            {selectedContent === 2 && <img className="img-fluid" src={getImagePath("Frame-11712755.webp")} alt="alternative" />}
                                            {selectedContent === 3 && <img className="img-fluid" src={getImagePath("Frame-1171275531.webp")} alt="alternative" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Enhancement" role="tabpanel" aria-labelledby="pills-Enhancement-tab">
                                <div className="row dir-rtl align-items-center">
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="sidebar">
                                            <ul>
                                                <li className={`marg-40 ${selectedContent === 4 ? 'active' : ''}`} onClick={() => handleSelectedContent(4)}>
                                                    <h6 className="title">{t("Image Quality Improvement")}</h6>
                                                    {selectedContent === 4 && <p className="para padd-0">{t("Enhance listing images by adjusting lighting")}</p>}
                                                </li>
                                                <li className={`marg-40 ${selectedContent === 5 ? 'active' : ''}`} onClick={() => handleSelectedContent(5)}>
                                                    <h6 className="title">{t("Image Compliance Checks")}</h6>
                                                    {selectedContent === 5 && <p className="para padd-0">{t("Ensure all images meet your guidelines")}</p>}
                                                </li>
                                                <li className={`marg-40 ${selectedContent === 6 ? 'active' : ''}`} onClick={() => handleSelectedContent(6)}>
                                                    <h6 className="title">{t("SEO Optimization")}</h6>
                                                    {selectedContent === 6 && <p className="para padd-0">{t("Generate relevant keywords and tags to improve")}</p>}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="image-container">
                                            {selectedContent === 4 && <img className="img-fluid" src={getImagePath("Frame-1171272980.webp")} alt="alternative" />}
                                            {selectedContent === 5 && <img className="img-fluid" src={getImagePath("Frame-1127553.webp")} alt="alternative" />}
                                            {selectedContent === 6 && <img className="img-fluid" src={getImagePath("Frame-117127.webp")} alt="alternative" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Engagement" role="tabpanel" aria-labelledby="pills-Engagement-tab">
                                <div className="row dir-rtl align-items-center">
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="sidebar">
                                            <ul>
                                                <li className={`marg-40 ${selectedContent === 7 ? 'active' : ''}`} onClick={() => handleSelectedContent(7)}>
                                                    <h6 className="title">{t("Personalized Recommendations")}</h6>
                                                    {selectedContent === 7 && <p className="para padd-0">{t("Increase user engagement with content")}</p>}
                                                </li>
                                                <li className={`marg-40 ${selectedContent === 8 ? 'active' : ''}`} onClick={() => handleSelectedContent(8)}>
                                                    <h6 className="title">{t("Real-Time Seller Feedback")}</h6>
                                                    {selectedContent === 8 && <p className="para padd-0">{t("Provide immediate insights to sellers")}</p>}
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="image-container">
                                            {selectedContent === 7 && <img className="img-fluid" src={getImagePath("Frame-11712755317.webp")} alt="alternative" />}
                                            {selectedContent === 8 && <img className="img-fluid" src={getImagePath("Frame-11712755311.webp")} alt="alternative" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )

}
export default Features;