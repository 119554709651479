import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const Blogs: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };


    return (
        <>
           
            <div className="cards-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading"> {t("Blogs")}</h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-lg-12">
                            <div className="card" > 
                                <div className="card-body">
                                    <img className="quotes" src={getImagePath("blog-1.webp")} alt="alternative" />
                                    <div className="span-p d-flex ">
                                        <span className="bd-highlight-1"> {t("Real Estate")}</span>
                                        <p className=" bd-highlight-1" >{t("March 11, 2024")} </p>
                                    </div>
                                    <div className="testimonial-author"> {t("blog title 1")} </div>
                                    <p className="testimonial-text"> {t("blog desc 1")} </p>
                                    <div className="card-learn d-flex justify-content-start">
                                        <a className="learn_more"  href="/Blogs/boosting-real-estate-confidence-battling-fake-listings-with-AI" style={{ padding: "0px 20px 0px", marginTop: "20px" }}>{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src={getImagePath("blog-2.webp")} alt="alternative" />
                                    <div className="span-p d-flex ">
                                        <span className="bd-highlight-1 " > {t("Real Estate")}</span>
                                        <p className=" bd-highlight-1" >{t("January 21, 2024")} </p>
                                    </div>
                                    <div className="testimonial-author"> {t("blog title 2")} </div>
                                    <p className="testimonial-text"> {t("blog desc 2")} </p>
                                    <div className="card-learn d-flex justify-content-start">
                                        <a className="learn_more"  href="/Blogs/how-AI-is-fixing-real-estate-marketplace-flows" style={{ padding: "0px 20px 0px", marginTop: "20px" }}>{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src={getImagePath("blog-3.webp")} alt="alternative" />
                                    <div className="span-p d-flex ">
                                        <span className="bd-highlight-1" > {t("Real Estate")}</span>
                                        <p className=" bd-highlight-1" >{t("November 16, 2023")} </p>
                                    </div>
                                    <div className="testimonial-author"> {t("blog title 3")} </div>
                                    <p className="testimonial-text"> {t("blog desc 3")} </p>
                                    <div className="card-learn d-flex justify-content-start">
                                        <a className="learn_more"  href="/Blogs/enhancing-the-visuals-with-AI-A-revolutionary-approach-to-real-estate-marketing" style={{ padding: "0px 20px 0px", marginTop: "20px" }}>{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src={getImagePath("blog-4.webp")} alt="alternative" />
                                    <div className="span-p d-flex ">
                                        <span className="bd-highlight-1"> {t("Real Estate")}</span>
                                        <p className=" bd-highlight-1" >{t("September 28, 2023")} </p>
                                    </div>
                                    <div className="testimonial-author"> {t("blog title 4")} </div>
                                    <p className="testimonial-text"> {t("blog desc 4")} </p>
                                    <div className="card-learn d-flex justify-content-start">
                                        <a className="learn_more"  href="/Blogs/AI-powered-room-staging-A-game-changer-for-real-estate" style={{ padding: "0px 20px 0px", marginTop: "20px" }}>{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src={getImagePath("blog-5.webp")} alt="alternative" />
                                    <div className="span-p d-flex ">
                                        <span className="bd-highlight-1" > {t("Real Estate")}</span>
                                        <p className=" bd-highlight-1" >{t("September 25,2023")} </p>
                                    </div>
                                    <div className="testimonial-author"> {t("blog title 5")} </div>
                                    <p className="testimonial-text"> {t("blog desc 5")} </p>
                                    <div className="card-learn d-flex justify-content-start">
                                        <a className="learn_more"  href="/Blogs/the-power-of-listing-videos-boosting-conversions-for-real-estate-agents" style={{ padding: "0px 20px 0px", marginTop: "20px" }}>{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src={getImagePath("blog-6.webp")} alt="alternative" />
                                    <div className="span-p d-flex ">
                                        <span className="bd-highlight-1" > {t("Real Estate")}</span>
                                        <p className=" bd-highlight-1" >{t("August 13, 2023")} </p>
                                    </div>
                                    <div className="testimonial-author"> {t("blog title 6")} </div>
                                    <p className="testimonial-text"> {t("blog desc 6")} </p>
                                    <div className="card-learn d-flex justify-content-start">
                                        <a className="learn_more"  href="/Blogs/list-properties-quicker-with-AI-property-description" style={{ padding: "0px 20px 0px", marginTop: "20px" }}>{t("Learn More")}</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Blogs;