import { useTranslation } from "react-i18next";
import GetStarted from "./get-started";

const Footer: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();



    return (<>
        <div className="basic-7 marg-2rem">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-xl-12">
                        <div className="padd-basic-7">
                            <h1> {t("increase your ROI with")} </h1>
                            <p>{t("Let’s talk to us")} </p>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                    <GetStarted Style={"butt-started"} Value={`${t("Get Started")}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    </>
    )

}
export default Footer;