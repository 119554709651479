import './App.css';
import PublicRouter from './Router/PublicRouter';
import { HelmetProvider } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const App: React.FunctionComponent<{}> = (props) => {
  const location = useLocation();
 
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-L9CXJZ2521', { page_path: location.pathname });
    }
  }, [location]);
  return (
    <HelmetProvider>
      <PublicRouter />
    </HelmetProvider>
  );
}

export default App;
