import { useTranslation } from "react-i18next";
import Section2 from "../home/section-2";
import AIListing from "../home/AI-listing";
import VirtualStaging from "./virtual-staging";
import Portals from "../AI Listing/Portals";
import Section7 from "../home/section-7";
import Testimonials from "../AI Listing/Testimonials";
import Questions from "../AI Listing/Questions";
import Benefits from "./Benefits";
import { Helmet } from "react-helmet-async";
import NavigationIcon from '@mui/icons-material/Navigation';
import FormFreeDemo from "./FormFreeDemo";
import { Fab } from "@mui/material";
import GetStarted from "../home/get-started";


const RealEstateAgencie: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();


    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div>
                <nav id="navbarExample" className="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation" style={{paddingTop:"15px", paddingBottom:"15px"}}>
                    <div className="container">
                        <a className="navbar-brand logo-image" href="/home"><img src="/assets/images/Coralytics trans.webp" alt="alternative" /></a>
                    </div>
                </nav>
                <Helmet>
                    <title>{t("Helping Real Estate")}</title>
                    <meta name="description" content={t("80+AI Capabilities to create")} />
                </Helmet>

                {/* section 1 */}
                <header id="header" className="header  page-home dir-rtl ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="text-container">
                                    <h1 className="h1-large">{t("Helping Real Estate")}</h1>
                                    <p className="p-large">{t("80+AI Capabilities to create")}</p>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-center">
                                <FormFreeDemo />
                            </div>
                        </div>
                    </div>
                </header>

                {/* Real estate agents and agencies rely on Coralytics*/}
                <Section2 />

                {/* AI Listing Creation */}
                <AIListing />
                <div className='basic-11'>
                    <div className="container">
                        <div className="card">
                            <div className='row d-flex justify-content-center align-items-center align-self-center'>
                                <div className='col-lg-12 col-xl-12 '>
                                    <h1> {t("Take your real estate")} </h1>
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                            <GetStarted Style={"btn-solid-lg"} Value={`${t("Get Started For Free")}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Generate Videos and 3D Floor plans and Image Enhancement and Virtual Staging*/}
                <VirtualStaging />

                <Portals />

                {/* Benefits */}
                <Benefits />

                {/*Boost your return on marketing */}
                <Section7 />

                <Testimonials />

                <Questions />

                <div className="basic-7 marg-2rem">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12">
                                <div className="padd-basic-7">
                                    <h1> {t("increase your ROI with")} </h1>
                                    <p>{t("Let’s talk to us")} </p>
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                            <GetStarted Style={"butt-started"} Value={`${t("Get Started For Free")}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <Fab
                    variant="extended"
                    size="small"
                    onClick={handleScrollToTop}
                    style={{ position: 'fixed', bottom: 20, right: 20, background: "#573CFF", color: "#FFFFFF" }} >
                    <NavigationIcon sx={{ mr: 1 }} />
                    {t("Book Demo For Free")}
                </Fab>
                <div className="basic-8">
                    <div className="container">

                    </div >
                </div>
            </div>
        </>
    )

}
export default RealEstateAgencie;